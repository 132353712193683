import { computed, readonly, ref, watch } from 'vue';
import { activeApplicationId } from '@/router_NEW';

import useArtifacts from '@/composables_NEW/useArtifacts';
import useGlueCharmServices from './useGlueCharmServices';
import usePersistentData from '@/composables_NEW/usePersistentData';

import { ApplicationArtifactTypes, ArtifactTypes } from '@/constants/artifacts';

const { fetchGlueCharmMethod } = useGlueCharmServices();
const { savePersistentData, getPersistentData } = usePersistentData();

const { artifacts, cleanArtifactsByType, setArtifacts, editedArtifacts, saveLocalArtifactChanges } = useArtifacts({
  onUpdateApplication: updateApplicationPersistentData,
});

const readOnlyMode = ref();
const lastCreatedApplicationId = ref();
const activeAppplicationFullLoaded = ref(false);

const applicationArtifactsData = computed(() => ({
  ...ApplicationArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = artifacts[artifactType];
    return acc;
  }, {}),
}));

const fullActiveApplication = computed(() => ({
  ...activeApplication.value,
  ...applicationArtifactsData.value,
}));

const activeApplication = computed(() => activeApplicationId.value &&  {
  ...artifacts[ArtifactTypes.Application][activeApplicationId.value],
});

function updateApplicationPersistentData(appId) {
  const newPersistentData = ApplicationArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = Object.values(artifacts[artifactType]);
    return acc;
  }, {});
  savePersistentData(appId, newPersistentData);
}

async function getAllApplicationArtifacts(appId) {
  const cacheArtifacts = await getPersistentData(appId);

  if (cacheArtifacts) {
    Object.keys(cacheArtifacts).forEach(artifactType => {
      cleanArtifactsByType(artifactType);
      setArtifacts(artifactType, cacheArtifacts[artifactType] || []);
    });
  }

  const response = await fetchGlueCharmMethod('mass-get-generic-entities', `getAllApplicationArtifacts-${appId}`, {
    ownership_application_id: appId,
    generation_version: 'v0',
  }, 'gluex');

  if (response?.success) {
    const responseArtifacts = response?.data?.entities;
    console.log(`responseArtifacts:`, responseArtifacts);
    
    Object.keys(responseArtifacts).forEach(artifactType => {
      Object.keys(editedArtifacts[artifactType] || {}).forEach(artifactId => {
        const responseArtifact = responseArtifacts[artifactType].find(item => item.id === artifactId);
        const editedArtifact = editedArtifacts[artifactType][artifactId];
        if (responseArtifact?.version === editedArtifact?.version) {
          responseArtifacts[artifactType] = responseArtifacts[artifactType].map(item => item.id === artifactId ? artifacts[artifactType][artifactId] : item);
          saveLocalArtifactChanges(artifactType, artifactId);
        }
      });
    });

    Object.keys(responseArtifacts).forEach(artifactType => {
      cleanArtifactsByType(artifactType);
      setArtifacts(artifactType, responseArtifacts[artifactType]);
    });

    updateApplicationPersistentData(appId);
  }
}

async function getFullApplicationContent() {
  activeAppplicationFullLoaded.value = false;
  await getAllApplicationArtifacts(activeApplicationId.value),
  activeAppplicationFullLoaded.value = true;
}

async function createLegacyApplicationClone(appId, workspaceId, name, description) {
  console.log(`createLegacyApplicationClone:`, appId);
  const created = await fetchGlueCharmMethod('application-manager/create', 'createApplicationClone', {
    id: appId,
    organization_id: workspaceId,
    shortname: name.slice(0, 5),
    name,
    description,
  });
  return created;
}

watch(activeApplicationId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    getFullApplicationContent();
  }
}, {immediate: true});

export default function useApplications() {
  return {
    readOnlyMode: readonly(readOnlyMode),
    lastCreatedApplicationId: readonly(lastCreatedApplicationId),
    activeApplication,
    fullActiveApplication,
    getFullApplicationContent,
    activeAppplicationFullLoaded,
    createLegacyApplicationClone,
  };
}
