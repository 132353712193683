import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useApplications from '@/composables_NEW/useApplications';
import useFetching from '@/composables_NEW/useFetching';
import useArtifacts from '@/composables_NEW/useArtifacts';

import { ArtifactDataModel, ArtifactFieldBases, ArtifactTypes } from '@/constants/artifacts';
import { nextTick } from 'vue';

const { fetchGlueCharmMethod } = useGlueCharmServices();
const { activeApplication } = useApplications();
const { addFetchingAction, setFetchingActionDone } = useFetching();

const {
  artifacts, createNewLocalArtifact, saveLocalArtifactChanges, deleteArtifact,
  updateLocalArtifact, batchGetArtifactsByIds, batchSaveArtifacts, getArtifactById,
} = useArtifacts();

async function runSimpleAction({action, inputData, fetchingAction}) {
  const fetchingActionId = fetchingAction || `runSimpleAction-${action}`;
  
  addFetchingAction(fetchingActionId);
  
  const response = await fetchGlueCharmMethod('callAgentAction', fetchingActionId, {
    action,
    inputData,
  });
  
  return response;
}

async function generateEntityList({type, id, fieldType, appId, limit}) {
  if (!appId && !activeApplication.value?.id  ) {
    return [];
  }
  
  const aiResponse = await fetchGlueCharmMethod('generate-entity-list', `generate-${id}-${fieldType}`, {
    parent_artifact_name: type,
    artifact_name: fieldType,
    parent_item_id: id,
    generation_version: 'v0',
    ownership_application_id: appId || activeApplication.value.id,
    is_suggestion: true,
    disable_cache: true,
    item_limit: limit,
  }, 'gluex');
  
  return aiResponse?.data?.items;
}

async function generateEntityPrimitiveFields({type, id, field}, saveContents = false) {
  const artifactData = artifacts[type][id];

  const aiResponse = await fetchGlueCharmMethod('generate-entity-primitive-fields', `generate-${id}-primitive`, {
    artifact_name: type,
    item_id: id,
    generation_version: 'v0',
    artifact_field: field,
    ownership_application_id: type === ArtifactTypes.Application ? artifactData?.id : artifactData?.ownership_application_id || activeApplication.value?.id,
    is_suggestion: !saveContents,
    disable_cache: true,
  }, 'gluex');
  
  return aiResponse?.data?.item;
}

async function generateAutomaticContent({type, id, field}, saveContents = false) {
  const aiResponse = await fetchGlueCharmMethod('generate-automatic-content', `generate-${id}-${field}`, {
    starting_artifact: type,
    item_id: id,
    artifact_field: field,
    generation_version: 'v0',
    is_suggestion: !saveContents,
    disable_cache: true,
  }, 'gluex');

  return aiResponse?.data?.item?.[field];
}

async function handleArrayArtifactGeneration({type, id, field}) {
  const artifactFieldModel = ArtifactDataModel[type].fields?.[field.field];
  const artifactData = artifacts[type][id];
  const appId = type === ArtifactTypes.Application ? artifactData.id : artifactData.ownership_application_id;

  const aiSuggestion = await generateEntityList({type, id, fieldType: artifactFieldModel.type, appId, limit: field.limit});

  const itemsCreated = (aiSuggestion || []).map(item => {
    const newId = createNewLocalArtifact(artifactFieldModel.type, {
      ...item,
      ownership_application_id: appId,
      description: item.description || item.summary,
    });
    return newId;
  });

  updateLocalArtifact(type, id, {
    [field.field]: itemsCreated,
  });

  if (itemsCreated?.length) {
    if (field.forceFields) {
      itemsCreated.forEach(itemId => {
        updateLocalArtifact(artifactFieldModel.type, itemId, Object.entries(field.forceFields).reduce((acc, [field, value]) => ({
          ...acc,
          [field]: value,
        }), {}));
      });
    }

    await batchSaveArtifacts(artifactFieldModel.type, itemsCreated.map(itemId => artifacts[artifactFieldModel.type][itemId]));
    
    if(field.generatePrimitiveFields) {
      const itemPrimitiveFields = Object.keys(ArtifactDataModel[artifactFieldModel.type].fields || {})
        .filter((fieldId) => !['id', 'ownership_application_id', ...Object.keys(field.forceFields || {})].includes(fieldId) 
          && ArtifactDataModel[artifactFieldModel.type].fields[fieldId].base === ArtifactFieldBases.Primitive);
       
      await Promise.all(itemsCreated.map(async (itemId) => {
        const aiResponse = await generateEntityPrimitiveFields({type: artifactFieldModel.type, id: itemId});
        if (aiResponse) {
          updateLocalArtifact(artifactFieldModel.type, itemId, itemPrimitiveFields.reduce((acc, fieldId) => ({
            ...acc,
            [fieldId]: aiResponse[fieldId],
          }), {}));
        }
        return true;
      }));
      
      await batchSaveArtifacts(artifactFieldModel.type, itemsCreated.map(itemId => artifacts[artifactFieldModel.type][itemId]));
    }
    
    await batchGetArtifactsByIds(artifactFieldModel.type, itemsCreated);
  
    if (field.extraFields?.length) {
      await Promise.all(itemsCreated.map((itemId) => {
        Promise.all(field.extraFields.map((extraField, index) => {
          return generateArtifactFieldOnBack({type: artifactFieldModel.type, id: itemId, field: extraField}, index === field.extraFields.length - 1);
        }));
      }));
    }
  }

  return true;
}

async function handleReferenceArtifactGeneration({type, id, field}) {
  const referencePromise = async () => {
    const aiResponse = await generateAutomaticContent({type, id, field: field.field}, true);
    updateLocalArtifact(type, id, {
      [field.field]: aiResponse,
    });
    return true;
  };

  const primitivesPromise = async () => {
    if(field.generatePrimitiveFields) {
      generateArtifactFieldOnBack({type, id, field: {generatePrimitiveFields: true}});
    }
    return true;
  };

  await Promise.all([
    referencePromise(),
    primitivesPromise(),
  ]);

  saveLocalArtifactChanges(type, id);
  
  return true;
}

async function handleSingleArtifactGeneration({type, id, field}) {
  const artifactFieldModel = ArtifactDataModel[type].fields?.[field.field];
  const artifactData = artifacts[type][id];
  const oldFieldValue = artifactData[field.field];
  const appId = type === ArtifactTypes.Application ? artifactData.id : artifactData.ownership_application_id;

  const aiSuggestion = await generateArtifactWithFreeInput({
    type: artifactFieldModel.type,
    fields: ['name', 'description', 'summary'],
    userPrompt: artifactData.summary,
    multiple: false,
  });
  
  const newItemId = createNewLocalArtifact(artifactFieldModel.type, {
    ...aiSuggestion[0],
    ownership_application_id: appId,
  });
  
  await batchSaveArtifacts(artifactFieldModel.type, [artifacts[artifactFieldModel.type]?.[newItemId]]);
  
  updateLocalArtifact(type, id, {
    [field.field]: newItemId,
  });

  if (oldFieldValue) {
    deleteArtifact(artifactFieldModel.type, oldFieldValue);
  }
    
  if (field.extraFields?.length) {
    await Promise.all(field.extraFields.map(async (extraField) => {
      await generateArtifactFieldOnBack({type: artifactFieldModel.type, id: newItemId, field: extraField}, false);
      return true;
    }));
  }
  
  saveLocalArtifactChanges(artifactFieldModel.type, newItemId, false);
  return true;
}

async function handlePrimitiveFieldsGeneration({type, id}) {
  const artifactPrimitiveFields = Object.keys(ArtifactDataModel[type].fields || {})
    .filter((fieldId) => !['id', 'ownership_application_id'].includes(fieldId) 
      && ArtifactDataModel[type].fields[fieldId].base === ArtifactFieldBases.Primitive);

  const aiResponse = await generateEntityPrimitiveFields({type, id});
  
  updateLocalArtifact(type, id, artifactPrimitiveFields.reduce((acc, fieldId) => ({
    ...acc,
    [fieldId]: aiResponse[fieldId],
  }), {}));

  return true;
}

async function generateArtifactFieldOnBack({type, id, field = {}}, saveArtifact = true) {
  const artifactData = artifacts[type][id];

  if (!artifactData) {
    return false;
  }
  
  let fetchingAction;
  
  if (field?.field) {
    const artifactFieldModel = ArtifactDataModel[type].fields?.[field.field];
    const appId = type === ArtifactTypes.Application ? artifactData.id : artifactData.ownership_application_id;
    
    fetchingAction = `generate-back-${id}-${field.field}`;
    addFetchingAction(fetchingAction);

    if (artifactFieldModel?.base === ArtifactFieldBases.Artifact) {
      if (artifactFieldModel._is_array && !artifactFieldModel._is_reference) {
        await handleArrayArtifactGeneration({type, id, field});
      } else if (artifactFieldModel._is_reference || artifactFieldModel.type === ArtifactTypes.File) {
        await handleReferenceArtifactGeneration({type, id, field});
      } else {
        await handleSingleArtifactGeneration({type, id, field, artifactFieldModel, artifactData, appId});
      }
    } else {
      const aiResponse = await generateEntityPrimitiveFields({type, id, field: field.field});
      if (aiResponse) {
        updateLocalArtifact(type, id, {
          [field.field]: aiResponse[field.field],
        });
      }
    }
  } else if (field.generatePrimitiveFields) {
    fetchingAction = `generate-back-${id}-primitive-fields`;
    addFetchingAction(fetchingAction);
    
    await handlePrimitiveFieldsGeneration({type, id});
  }

  if (saveArtifact) {
    saveLocalArtifactChanges(type, id);
  }

  nextTick(() => {
    setFetchingActionDone(fetchingAction);
  });

  return true;
}

async function generateArtifactWithFreeInput({type, fields, userPrompt, files = [], multiple = false}, saveContents = false) {
  const aiResponse = await fetchGlueCharmMethod('generate-entity-with-free-input', `generate-free-${type}`, {
    generation_version: 'v0',
    output_entity_name: type,
    output_entity_fields_to_generate: fields,
    free_contextual_string: userPrompt,
    free_contextual_files: files,
    is_suggestion: !saveContents,
    generate_list: multiple,
    disable_cache: true,
  }, 'gluex');

  if (multiple) {
    return aiResponse?.data?.entities;
  }

  return [aiResponse?.data?.entities[0]?.[type] || aiResponse?.data?.entities[0]];
}

export default function useAgent() {
  return {
    runSimpleAction,
    generateEntityList,
    generateArtifactFieldOnBack,
    generateArtifactWithFreeInput,
    generateEntityPrimitiveFields,
  };
}
