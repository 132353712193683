import { ref } from 'vue';

import { Language } from '@/constants/common';

const savedLanguage = localStorage.getItem('__GC__lang');
const browserLanguage = window.navigator.language.split('-')[0];

const defaultLanguage = savedLanguage || Object.values(Language).includes(browserLanguage) ? browserLanguage : Language.EN;

const literals = ref();
const activeLanguage = ref();

export default function useLanguages () {
  async function setLanguage (lang) {
    const newLanguage = Language.EN //FORCE ENGLISH --> lang || defaultLanguage;
    const literalsJson = await import(`../literals/${newLanguage}.json`);

    literals.value = literalsJson.default;
    activeLanguage.value = newLanguage;
    localStorage.setItem('__GC__lang', newLanguage);
  }

  function t (key, properties) {
    let literal = literals.value?.[key] ?? key;
    if (properties) {
      Object.keys(properties).forEach((property) => {
        literal = literal.replace(`{{${property}}}`, properties[property]);
      });
    }
    return literal;
  }

  return {
    setLanguage,
    t,
    activeLanguage,
  };
}
