<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import useFetching from '@/composables_NEW/useFetching';

import Tooltip from './Tooltip.vue';
import LoadingIcon from '@/svg/loading.svg';

import { getNewTestId } from '@/utils/utils';
import { CheckTypes } from '@/constants/forms';

const props = defineProps({
  modelValue: Boolean,
  value: String,
  labelLink: String,
  errorMessage: String,
  fetchingAction: String,
  required: Boolean,
  checked: Boolean,
  disabled: Boolean,
  id: String,
  labelBold: Boolean,
  label: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: CheckTypes.Checkbox,
  },
});

const router = useRouter();
const { fetchingActions, fetchingErrors, removeFetchingError } = useFetching();

const paddingX = 10;

const emit = defineEmits(['update:modelValue', 'check', 'uncheck']);

const inputRef = ref();
const errorTooltipRef = ref();
const checkValue = ref(props.checked ? props.value : null);

const inputId = computed(() => getNewTestId(props.id || (`${props.label}${props.label ? '-' : ''}checkbox`)));

const isFetching = computed(() => {
  return props.fetchingAction && fetchingActions.value.has(props.fetchingAction);
});

const error = computed(() => {
  return props.fetchingAction && fetchingErrors.value[props.fetchingAction];
});

function hideError () {
  errorTooltipRef.value.hide();
}

function showError () {
  errorTooltipRef.value.show(error.value, {
    top: 0,
    left: paddingX,
  });
}

function validate () {
  const isValid = !props.required || inputRef.value.checked;

  if (!isValid) {
    showError();
  }

  return isValid;
}

function clear () {
  inputRef.value.checked = false;
}

function onChangeInput() {
  checkValue.value = inputRef.value.checked ? props.value || true : null;
  emit('update:modelValue',  checkValue.value);

  if (inputRef.value.checked) {
    emit('check');
  } else {
    emit('uncheck');
  }

  hideError();
}

function onClickInput() {
  if (!!inputRef.value?.checked && !!checkValue.value) {    
    inputRef.value.checked = false;
    onChangeInput();
  }
}

function onClickLabel(ev) {
  if (props.labelLink) {
    ev.preventDefault();
    router.push(props.labelLink);
  }
}

function clearError() {
  removeFetchingError(props.fetchingAction);
}

watch(() => props.checked, () => {
  checkValue.value = props.checked ? props.value || true : false;
});

watch(error, (newVal) => {
  if (newVal) {
    showError();
  } else {
    hideError();
  }
});

defineExpose({
  clear,
  validate,
  value: checkValue,
});
</script>

<template>
  <div
    class="relative font-medium flex items-center"
    ref="rootRef"
  >
    <Tooltip
      ref="errorTooltipRef"
      :message="error"
      :close-button="true"
      :auto-hide="3000"
      @clickCloseButton="clearError"
    />
    <LoadingIcon v-if="isFetching" class="w-6 h-6 absolute -top-1 -left-1 z-10"/>
    <input
      ref="inputRef"
      data-hj-allow
      :id="inputId"
      :name="inputId"
      :data-test="inputId"
      class="flex-shrink-0 relative w-4 h-4 transition-colors cursor-pointer"
      :class="[
        errorTooltipRef?.showing ? 'border-red-900' : 'border-gray-400',
        type === CheckTypes.Checkbox ? 'rounded-sm' : '',
        disabled || isFetching ? 'checked:bg-gray-400 opacity-50' : ''
      ]"
      :checked="modelValue || !!checkValue"
      :disabled="disabled || isFetching"
      :type="type"
      @change="onChangeInput"
      @click="onClickInput"
    >
    <label
      class="ml-2 cursor-pointer text-xs font-bold"
      :class="[
        labelLink && 'cursor-pointer hover:underline',
        labelBold ? 'opacity-100' : 'opacity-50',
      ]"
      :for="inputId"
      @click="onClickLabel"
    >
      <div>{{ label }}</div>
      <slot />
    </label>
  </div>
</template>

<style lang="postcss">
input[type=checkbox],
input[type=radio]{
  &:autofill {
    -webkit-text-fill-color: rgb(55 48 163) !important;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
